import { useEffect, useState } from "react";
import axios from "axios";
import { apiURL } from "../../constants";
import { useLoading } from "../Hooks/LoadingProvider";
import { useAuth } from "../Hooks/AuthProvider";
import { IReview, ITrainingSpot } from "../Interfaces";
import useErrorData from "../Hooks/ErrorData";
import { ErrorList } from "../Components/ErrorList/ErrorList";
import { TrainingSpot } from "../Components/TrainingSpot/TrainingSpot";
import { useNavigate } from "react-router";
import { LoadingSpinner } from "seb-components-library";
import { MyReview } from "../Components/MyReview/MyReview";
import { CustomLink } from "../Components/CustomLink/CustomLink";

export const MyTrainingSpots = () => {
    const { setIsLoading } = useLoading();
    const { user, token } = useAuth();
    const [errorData, setErrors] = useErrorData()
    const navigate = useNavigate()
    // training spots
    const [trainingSpots, setTrainingSpots] = useState<ITrainingSpot[]>([]);
    const [trainingSpotsSkip, setTrainingSpotsSkip] = useState<number>(0)
    const [isLoadingMoreTrainingSpots, setIsLoadingMoreTrainingSpots] = useState<boolean>(false)
    // reviews
    const [reviews, setReviews] = useState<IReview[]>([]);
    const [reviewsSkip, setReviewsSkip] = useState<number>(0)
    const [isLoadingMoreReviews, setIsLoadingMoreReviews] = useState<boolean>(false)

    const fetchTrainingSpots = async (trainingSpotsSkip: number) => {
        try {
            const trainingSpotsResponse = await axios.get(`${apiURL}/api/TrainingSpots/ByUser/${user.sub}?skip=${trainingSpotsSkip}&take=8`, {
                headers: {
                    Authorization: token
                }
            });
            setTrainingSpots((prev) => {
                const existingIds = new Set(prev.map((spot) => spot.id));
                const filteredNewSpots: ITrainingSpot[] = trainingSpotsResponse.data.filter((spot: ITrainingSpot) => !existingIds.has(spot.id));
                return [...prev, ...filteredNewSpots];
            });
        } catch (error: any) {
            setErrors(error.response.data.errors)
        } finally {
            setIsLoading(false)
            setIsLoadingMoreTrainingSpots(false)
        }
    };
    const fetchReviews = async (skip: number) => {
        try {
            const reviewsSpotsResponse = await axios.get(`${apiURL}/api/Review/byUser/${user.sub}?skip=${skip}&take=4`, {
                headers: {
                    Authorization: token
                }
            });
            setReviews((prev) => {
                const existingIds = new Set(prev.map((reviews) => reviews.id));
                const filteredReviews: IReview[] = reviewsSpotsResponse.data.filter((review: IReview) => !existingIds.has(review.id));
                return [...prev, ...filteredReviews];
            });
        } catch (error: any) {
            setErrors(error.response.data.errors)
        } finally {
            setIsLoading(false)
            setIsLoadingMoreReviews(false)
        }
    };

    const deleteTrainingSpot = async (trainingSpotId: string) => {
        try {
            setIsLoading(true)
            const response = await axios.delete(`${apiURL}/api/TrainingSpots/${trainingSpotId}`,
                {
                    headers: {
                        Authorization: token
                    }
                });
            const updatedTrainingSpots = trainingSpots.filter((spot) => spot.id !== response.data.id);
            setTrainingSpots(updatedTrainingSpots);
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    };

    const handleEditClick = (id: string) => {
        navigate(`/edit-training-spot/${id}`)
    }

    useEffect(
        () => {
            setIsLoading(true)
            fetchTrainingSpots(trainingSpotsSkip)
            fetchReviews(reviewsSkip)
            // eslint-disable-next-line
        }, []
    )

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '2px' }}>
            <h2>My uploaded training spots</h2>
            <CustomLink to={"/add-new-training-spot"}>Add new</CustomLink>
            {trainingSpots.map(trainingSpot => {
                return (
                    <TrainingSpot
                        editable
                        key={trainingSpot.id}
                        trainingSpot={trainingSpot}
                        onDeleteClick={deleteTrainingSpot}
                        onEditClick={() => {
                            setIsLoading(true)
                            setTimeout(() => { handleEditClick(trainingSpot.id) }, 0);
                        }}
                    />
                )
            })}
            {!isLoadingMoreTrainingSpots ?
                <button onClick={() => {
                    setIsLoadingMoreTrainingSpots(true)
                    fetchTrainingSpots(trainingSpotsSkip + 8)
                    setTrainingSpotsSkip((prev => prev + 8))
                }}>Load more</button> : <LoadingSpinner />
            }
            <h2>My reviews</h2>
            {reviews.map(review => {
                return (
                    <MyReview review={review} user={user} />
                )
            })}
            {!isLoadingMoreReviews ?
                <button onClick={() => {
                    setIsLoadingMoreReviews(true)
                    fetchReviews(reviewsSkip + 4)
                    setReviewsSkip((prev => prev + 4))
                }}>Load more</button> : <LoadingSpinner />
            }
            <ErrorList errors={errorData} />
        </div >
    )
}