import axios from 'axios';
import { useEffect, useState } from 'react';
import { apiURL } from '../../constants'
import { useLoading } from '../Hooks/LoadingProvider';
import useErrorData from '../Hooks/ErrorData';
import { ErrorList } from '../Components/ErrorList/ErrorList';
interface FormData {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
}

export const RegisterPage = () => {
  const [formData, setFormData] = useState<FormData>({
    name: '',
    email: '',
    password: '',
    confirmPassword: ''
  });
  const [responseMessage, setResponseMessage] = useState<string | null>(null);
  const { setIsLoading } = useLoading();
  const [registrationSuccess, setRegistrationSuccess] = useState<boolean>(false)
  const [errorData, setErrors] = useErrorData()


  const handleChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  useEffect(() => {
    setIsLoading(false)
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    try {
      setIsLoading(true)
      setResponseMessage(null)
      const response = await axios.post(
        `${apiURL}/api/Account/register`,
        { ...formData }
      );
      if (response.status === 200) {
        setResponseMessage(response.data)
        setRegistrationSuccess(true)
        setErrors([])
      }
    } catch (error: any) {
      console.log(error.response)
      setErrors(error.response.data.errors)
    } finally {
      setIsLoading(false)
    }
  };

  return (
    <div>
      <h2>Register</h2>
      {!registrationSuccess && <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="email">Email:</label>
          <input
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="confirmPassword">Confirm Password:</label>
          <input
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
          />
        </div>
        <button type="submit">Register</button>
      </form>}
      <ErrorList errors={errorData} />
      {responseMessage && <p>{responseMessage}</p>}
    </div>
  )
}

