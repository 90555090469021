import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { apiURL } from "../../constants";
import { useAuth } from "../Hooks/AuthProvider";
import { useLoading } from "../Hooks/LoadingProvider";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import { ErrorList } from "../Components/ErrorList/ErrorList";
import { initialLoginFormData } from "../Data/InitialData";
import useErrorData from "../Hooks/ErrorData";
import { LoginForm } from "../Interfaces";
import { useContent } from "../Hooks/ContentProvider";

export const LoginPage = () => {
  const [formData, setFormData] = useState<LoginForm>(initialLoginFormData);
  const { login } = useAuth();
  const { setIsLoading } = useLoading();
  const [searchParams] = useSearchParams();
  const [errorData, setErrors] = useErrorData();
  const navigate = useNavigate();
  const [message, setMessage] = useState<string>("");
  const isVerified = useRef<boolean>(false);
  const verifyToken = searchParams.get("verify-token");
  const { loginPage } = useContent();

  const handleChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const responseSuccessGoogle = async (response: any) => {
    const tokenId: string = response.credential;
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${apiURL}/api/Account/google-sign-in`,
        { TokenId: tokenId }
      );
      const { token } = response.data;
      navigate("/");
      login(token);
    } catch (error: any) {
      console.log("Error:", error);
      setIsLoading(false);
    }
  };

  const verifyEmail = async (token: string | null) => {
    setMessage("");
    setErrors([]);
    try {
      const response = await axios.get(`${apiURL}/api/Account/verify`, {
        params: { token },
      });
      setMessage(response.data);
    } catch (error: any) {
      isVerified.current = false;
      setErrors(error.response.data.errors);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${apiURL}/api/Account/login`,
        formData
      );
      const { token } = response.data;
      navigate("/");
      login(token);
    } catch (error: any) {
      setErrors(error.response.data.errors);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    !isVerified.current && verifyToken && verifyEmail(verifyToken);
    isVerified.current = true;
    isVerified.current && setIsLoading(false)
    // eslint-disable-next-line
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "5px",
      }}
    >
      <h2>{loginPage.header}</h2>
      {message && <p>{message}</p>}
      <ErrorList errors={errorData} />
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="email">Email:</label>
          <input
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="password">Password:</label>
          <input
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
          />
        </div>
        <button type="submit">Login</button>
        <GoogleLogin
          onSuccess={responseSuccessGoogle}
          onError={() => {
            console.log("Login Failed");
          }}
        />
      </form>
    </div>
  );
};
