import { useNavigate } from "react-router";
import { convertMetersToKilometers } from "../../Helpers/convertMetersToKilometers";
import { handleOutsideIndex } from "../../Helpers/handleOutsideIndex";
import { ITrainingSpotProps } from "../../Interfaces";
import { TrainingSpotPicture } from "../TraingSpotPicture/TrainingSpotPicture";
import "./TrainingSpot.scss";
import { useLoading } from "../../Hooks/LoadingProvider";

export const TrainingSpot = ({ trainingSpot, editable = false, onDeleteClick, onEditClick, handleShowOnMap }: ITrainingSpotProps) => {
  const navigate = useNavigate();
  const { setIsLoading } = useLoading();

  const imageArray = trainingSpot.trainingSpotPictureIds;
  const pictureIndex = handleOutsideIndex(imageArray, trainingSpot.mainPictureIndex);
  const pictureSrc = imageArray[pictureIndex];
  return (
    <div className="trainingSpotContainer">
      <div className="trainingSpotContainer__title">
        <div>
          <p>{trainingSpot.title}</p>
          <p>rating: {trainingSpot.currentRating}</p>
        </div>
        <div>
          {!editable && trainingSpot.distance !== null && (
            <p>{convertMetersToKilometers(trainingSpot.distance)} Km from you</p>
          )}
        </div>
      </div>
      <div className="trainingSpotContainer__pictures">
        {imageArray.length > 0 && <TrainingSpotPicture pictureId={pictureSrc} />}
        <div className="trainingSpotContainer__pictures__buttons">
          {editable && (
            <>
              <button onClick={() => onDeleteClick && onDeleteClick(trainingSpot.id)}>Delete</button>
              <button onClick={() => onEditClick && onEditClick(trainingSpot.id)}>Edit</button>
            </>
          )}
          <button onClick={() => {
            setIsLoading(true)
            setTimeout(() => { navigate(`/${trainingSpot.id}`) }, 0);
          }}
          >View</button>
          {handleShowOnMap && <button onClick={() => handleShowOnMap(trainingSpot.id)}>Show on map</button>}
        </div>
      </div>
    </div >
  );
};
