import { IGlobalContent, IHomePageContent, ILoginPageContent } from "./IContent";

export function localizedContent(language: string): IGlobalContent {
  const basePath = `./${language}`;

  const homePage: IHomePageContent = require(`${basePath}/homePage.json`);
  const loginPage: ILoginPageContent = require(`${basePath}/loginPage.json`);

  return {
    homePage,
    loginPage,
  };
}
