export function getLanguageFromDomain(domain: string) {
    switch (domain) {
        case 'localhost:3000':
            return 'en';
        case 'sports-advisor.pl':
            return 'pl';
        case 'www.sports-advisor.pl':
            return 'pl';
        case 'www.sports-advisor.hu':
            return 'hu';
        case 'sports-advisor.hu':
            return 'hu';
        case 'www.sports-advisor.lt':
            return 'lt';
        case 'sports-advisor.lt':
            return 'lt';
        case 'wonderful-sea-01e202a10.5.azurestaticapps.net':
            return 'pl';
        case 'localhost:4000':
            return 'pl';
        default:
            return 'en';
    }
}