import { ChangeEvent, FormEventHandler } from "react"
import { IReview } from "../../Interfaces"

interface IAddNewReviewForm {
    handleSubmit: FormEventHandler<HTMLFormElement> | undefined,
    handleEdit?: any,
    handleChange: (arg0: ChangeEvent<HTMLInputElement>, arg1: any, arg2: any) => void,
    setReviewFormData: any,
    reviewFormData: IReview | undefined,
    setEditing: any,
    editing: boolean
}

export const AddNewReviewForm = (
    { handleSubmit, handleEdit, handleChange, setReviewFormData, reviewFormData, setEditing, editing }: IAddNewReviewForm
) => {
    return (
        <div >
            <form onSubmit={editing ? handleEdit : handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <p>Write a review:</p>
                <input value={reviewFormData?.comment} type="text" name="comment" onChange={(e) => { handleChange(e, setReviewFormData, reviewFormData) }} />
                <div style={{ display: 'flex', gap: '0.75rem' }}>
                    <p>rating:</p>
                    {[1, 2, 3, 4, 5].map(
                        x => {
                            return (
                                <div key={x}>
                                    <input type="radio" name="rating" value={x}
                                        // eslint-disable-next-line
                                        checked={reviewFormData?.rating == x}
                                        onChange={(e) => { handleChange(e, setReviewFormData, reviewFormData) }}
                                    />
                                    <label htmlFor="rating">{x}</label>
                                </div>
                            )
                        }
                    )}
                    <button type="submit">Submit review</button>
                    {
                        editing && <button onClick={() => { setEditing(false) }}>Cancel</button>
                    }
                </div>
            </form>
        </div>
    )
}