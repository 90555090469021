import { ChangeEvent, FormEvent, MouseEvent, useEffect, useMemo, useRef, useState } from "react";
import { useLoading } from "../Hooks/LoadingProvider";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router";
import { apiURL, defaultMapZoom, googleMapsKey, mapId } from "../../constants";
import { useAuth } from "../Hooks/AuthProvider";
import useErrorData from "../Hooks/ErrorData";
import { containerStyle, initialGpsData, initialTrainingSpotDta } from "../Data/InitialData";
import { TrainingSpotTagDictionary, TrainingSpotTypesDictionary } from "../Data/TrainingSpotTags";
import { GpsLocation, IFile, createTrainingSpotDTO } from "../Interfaces";
import { ErrorList } from "../Components/ErrorList/ErrorList";
import { GenerateGuid } from "../Helpers/GenerateGuid";
import { TrainingSpotPicture } from "../Components/TraingSpotPicture/TrainingSpotPicture";
import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import { getInitialLocation, requestAccurateGpsDetais } from "../Helpers/GetLocation";
import { handleOutsideIndex, handleSelectedDelete } from "../Helpers/handleOutsideIndex";
import { handleChange } from "../Helpers/UpdateFormData";

export const AddNewTrainingSpotPage = () => {
    const [selectedFiles, setSelectedFiles] = useState<IFile[]>([]);
    const [trainingSpotFormData, setTrainingSpotFormData] = useState<createTrainingSpotDTO>({ ...initialTrainingSpotDta });
    const { setIsLoading } = useLoading();
    const [selectedImageId, setSelectedImageId] = useState<string>();
    const { token } = useAuth();
    const fileInputRef = useRef<HTMLInputElement>(null)
    const [errorData, setErrors] = useErrorData()
    const navigate = useNavigate();
    const urls = useRef(new Set());
    const location = useLocation();
    const params = useParams();
    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: googleMapsKey!,
    });
    const gMapsRef = useRef<any>();
    const [center, setCenter] = useState<GpsLocation>(initialGpsData)
    const isEditing = location.pathname.startsWith('/edit-training-spot')
    const trainingSpotId = params.trainingSpotId

    const handleAddNewSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();

        let formData = new FormData();
        selectedFiles.forEach((file: IFile) => {
            formData.append('files', file.fileObject);
        });

        // Append JSON data to trainingSpotFormData
        formData.append('posX', trainingSpotFormData.posX.toString());
        formData.append('posY', trainingSpotFormData.posY.toString());
        formData.append('title', trainingSpotFormData.title?.toString());
        formData.append('mainPictureIndex', selectedFiles.map(x => x.id).findIndex(x => x === selectedImageId).toString());
        formData.append('comment', trainingSpotFormData.comment?.toString());
        formData.append('rating', trainingSpotFormData.rating?.toString());
        formData.append('tagIds', JSON.stringify(trainingSpotFormData.tagIds));

        try {
            setIsLoading(true)
            await axios.post(
                `${apiURL}/api/TrainingSpots`,
                formData,
                {
                    headers: {
                        Authorization: token
                    }
                }
            );
            setTrainingSpotFormData(initialTrainingSpotDta);
            setSelectedFiles([])
            setErrors([])
            navigate('/my-training-spots')
            fileInputRef.current!.value = ''
        } catch (error: any) {
            setErrors(error.response.data.errors)
        } finally {
            setIsLoading(false)
        }
    };

    const handleEditSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let formData = new FormData();
        selectedFiles.forEach((file: IFile) => {
            formData.append('files', file.fileObject);
        });
        // Append JSON data to trainingSpotFormData
        formData.append('id', trainingSpotId!);
        formData.append('posX', trainingSpotFormData.posX.toString());
        formData.append('posY', trainingSpotFormData.posY.toString());
        formData.append('title', trainingSpotFormData.title?.toString());
        formData.append('comment', trainingSpotFormData.comment?.toString());
        formData.append('rating', trainingSpotFormData.rating?.toString());
        formData.append('mainPictureIndex', [...trainingSpotFormData.trainingSpotPictureIds, ...selectedFiles.map(x => x.id)].findIndex(x => x === selectedImageId).toString());
        formData.append('trainingSpotPictureIds', JSON.stringify(trainingSpotFormData.trainingSpotPictureIds));
        formData.append('tagIds', JSON.stringify(trainingSpotFormData.tagIds));

        try {
            setIsLoading(true)
            await axios.put(
                `${apiURL}/api/TrainingSpots/${trainingSpotId}`,
                formData,
                {
                    headers: {
                        Authorization: token,
                    }
                }
            );
            navigate('/my-training-spots')
        } catch (error: any) {
            setErrors(error.response.data.errors)
        } finally {
            setIsLoading(false)
        }
    }

    // TODOL look for gmaps types 
    const handleMapClick = (event: any) => {
        setTrainingSpotFormData((prev) => {
            return (
                { ...prev, posX: event.latLng.lng(), posY: event.latLng.lat(), }
            )
        });
    };
    useEffect(() => {
        !isEditing && setIsLoading(false)
        // eslint-disable-next-line
    }, []);

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const files = Array.from(event.target.files)
            const filesObject = files.map(fileObject => {
                const id = GenerateGuid();
                const url = URL.createObjectURL(fileObject);
                urls.current.add(url);
                return (
                    { id: id, url: url, fileObject: fileObject }
                )
            })
            if (files.length > 0 && selectedFiles.length === 0 && trainingSpotFormData.trainingSpotPictureIds.length === 0) {
                setSelectedImageId(filesObject[0].id)
            }
            setSelectedFiles([...selectedFiles, ...filesObject])
        }
        // Reset the value of the file input element
        event.target.value = '';
    };

    const handleCenterChange = () => {
        if (gMapsRef.current) {
            const lat = gMapsRef.current.getCenter().lat();
            const lng = gMapsRef.current.getCenter().lng();
            setCenter({ ...center, lat: lat, lng: lng })
        }
    }

    useEffect(() => {
        let URLS = urls.current
        return () => {
            URLS.forEach(
                url => {
                    URL.revokeObjectURL(url as string)
                }
            )
        };
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true)
            try {
                const result = await getInitialLocation(setCenter) as GpsLocation;
                !isEditing && result.lat && result.lng
                    && setTrainingSpotFormData({ ...trainingSpotFormData, posX: result.lng, posY: result.lat })

            } catch (error) {
                console.log(error);
            } finally {
                setIsLoading(false)
            }
        };

        !isEditing && fetchData();
        // eslint-disable-next-line
    }, []);


    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true)
                const response = await axios.get(`${apiURL}/api/TrainingSpots/${trainingSpotId}`);
                setTrainingSpotFormData(response.data);
                const imageIndex = handleOutsideIndex(response.data.trainingSpotPictureIds, response.data.mainPictureIndex)
                setSelectedImageId(response.data.trainingSpotPictureIds[imageIndex]);
                setCenter({ ...center, lng: response.data.posX, lat: response.data.posY })
            } catch (error: any) {
                setErrors(error.response.data.errors)
            } finally {
                setIsLoading(false)
            }
        };
        isEditing && trainingSpotId && fetchData();
        // eslint-disable-next-line
    }, []);

    const mappedFiles = useMemo(() => {
        const handleImageDelete = (id: string, e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
            e.stopPropagation()
            const updatedSelectedFiles = selectedFiles.filter((file) => {
                if (file.id !== id) return true; // keep in state
                urls.current.delete(file.url);
                URL.revokeObjectURL(file.url);
                return false; // remove from state
            })
            handleSelectedDelete(trainingSpotFormData.trainingSpotPictureIds, updatedSelectedFiles, id, setSelectedImageId, selectedImageId)
            setSelectedFiles(updatedSelectedFiles);
        };
        const handleFormDataImageDelete = (pictureId: string, e: MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.stopPropagation()
            const updatedFormData: string[] = trainingSpotFormData.trainingSpotPictureIds.filter(id => id !== pictureId)
            handleSelectedDelete(updatedFormData, selectedFiles, pictureId, setSelectedImageId, selectedImageId)
            setTrainingSpotFormData(
                (prev: createTrainingSpotDTO) => {
                    return { ...prev, trainingSpotPictureIds: updatedFormData }
                }
            )
        }

        // TODO: handle errors to use errorData
        if (loadError) {
            return <p>error loading</p>
        }

        return (
            <>
                {isEditing && trainingSpotFormData?.trainingSpotPictureIds?.map((pictureId: string) => (
                    <div
                        onClick={() => { setSelectedImageId(pictureId) }}
                        key={pictureId}
                        style={{
                            outline: pictureId === selectedImageId ? '2px solid green' : 'none',
                            display: "flex", alignItems: "center", position: 'relative'
                        }}
                    >
                        <button
                            onClick={(e: any) => handleFormDataImageDelete(pictureId, e)}
                            style={{ position: 'absolute', top: '0', right: '0' }} >X</button>
                        <TrainingSpotPicture pictureId={pictureId} />
                    </div>
                ))}
                {selectedFiles?.map((file: IFile) => (
                    <div
                        onClick={() => { setSelectedImageId(file.id) }}
                        key={file.id}
                        style={{
                            outline: file.id === selectedImageId ? '2px solid green' : 'none',
                            display: "flex", alignItems: "center", position: 'relative'
                        }}
                    >
                        <button
                            onClick={(e) => handleImageDelete(file.id, e)}
                            style={{ position: 'absolute', top: '0', right: '0' }}
                        >X</button>
                        <img
                            style={{ height: "4rem", backgroundColor: "black" }}
                            src={file.url}
                            alt={"training spot"}
                        />
                    </div>
                ))}
            </>
        );
        // eslint-disable-next-line
    }, [selectedFiles, trainingSpotFormData, selectedImageId]);

    return (
        <>
            {center.error && <p>{center.error}</p>}
            <form onSubmit={(e) => { isEditing ? handleEditSubmit(e) : handleAddNewSubmit(e) }} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '0.5rem' }}>
                <div>
                    <label htmlFor="title">Title:</label>
                    <input
                        type="text"
                        name="title"
                        value={trainingSpotFormData.title}
                        onChange={(e) => { handleChange(e, setTrainingSpotFormData, trainingSpotFormData) }} />
                </div>
                <div>
                    <label htmlFor="comment">Review Comment:</label>
                    <input
                        type="text"
                        name="comment"
                        value={trainingSpotFormData.comment}
                        onChange={(e) => { handleChange(e, setTrainingSpotFormData, trainingSpotFormData) }} />
                </div>
                <h4>Rating</h4>
                <div style={{ display: 'flex' }}>
                    {[1, 2, 3, 4, 5].map(
                        x => {
                            return (
                                <div key={x} style={{ margin: '0rem .25rem' }}>
                                    {/* TODO: look into this warning */}
                                    {/* eslint-disable-next-line */}
                                    <input type="radio" name="rating" value={x} checked={trainingSpotFormData.rating == x}
                                        onChange={(e) => { handleChange(e, setTrainingSpotFormData, trainingSpotFormData) }}
                                    />
                                    <label htmlFor="rating">{x}</label>
                                </div>
                            )
                        }
                    )}
                </div>
                <h4>Facilities Available</h4>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {Object.keys(TrainingSpotTagDictionary).map(x => {
                        return (
                            <div key={x} style={{ display: 'flex' }}>
                                <input
                                    type="checkbox"
                                    name={x}
                                    key={x}
                                    checked={trainingSpotFormData.tagIds.includes(x.toLowerCase())}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            // If the checkbox is checked, add the id to the array
                                            setTrainingSpotFormData(prevState => ({
                                                ...prevState,
                                                tagIds: [...prevState.tagIds, x.toLowerCase()]
                                            }));
                                        } else {
                                            // If the checkbox is unchecked, remove the id from the array
                                            setTrainingSpotFormData(prevState => ({
                                                ...prevState,
                                                tagIds: prevState.tagIds.filter(id => id !== x.toLowerCase())
                                            }));
                                        }
                                    }}
                                />

                                <label htmlFor={x}>{TrainingSpotTagDictionary[x]}</label>
                            </div>
                        )
                    })}
                </div>
                <h4>Type</h4>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {Object.keys(TrainingSpotTypesDictionary).map(x => {
                        return (
                            <div key={x} style={{ display: 'flex' }}>
                                <input
                                    type="checkbox"
                                    name={x}
                                    checked={trainingSpotFormData.tagIds.includes(x.toLowerCase())}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            // If the checkbox is checked, add the id to the array
                                            setTrainingSpotFormData(prevState => ({
                                                ...prevState,
                                                tagIds: [...prevState.tagIds, x.toLowerCase()]
                                            }));
                                        } else {
                                            // If the checkbox is unchecked, remove the id from the array
                                            setTrainingSpotFormData(prevState => ({
                                                ...prevState,
                                                tagIds: prevState.tagIds.filter(id => id !== x.toLowerCase())
                                            }));
                                        }
                                    }}
                                />

                                <label htmlFor={x}>{TrainingSpotTypesDictionary[x]}</label>
                            </div>
                        )
                    })}
                </div>

                <div style={{ display: 'flex', gap: '5px' }}>
                    <input type="button" value="Add pictures +" onClick={() => { fileInputRef.current?.click() }} />
                    <input
                        type="file"
                        accept="image/png, image/gif, image/jpeg"
                        onChange={handleFileChange}
                        id="file-input"
                        ref={fileInputRef}
                        multiple
                        style={{ display: 'none' }}
                    />
                    <button type="submit">{isEditing ? 'Save edits' : 'Add Training Spot'}</button>
                    <button onClick={() => {
                        navigate('/my-training-spots')
                    }}>
                        Cancel
                    </button>
                </div>
                <button onClick={(e) => {
                    e.preventDefault()
                    requestAccurateGpsDetais(setIsLoading, setCenter);
                }}>Get accurate location</button>
                <div style={{ display: "flex", gap: '0.5rem', maxWidth: '20rem', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                    {mappedFiles}
                </div>
                <ErrorList errors={errorData} />
                {isLoaded && center.lat && center.lng &&
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        zoom={defaultMapZoom}
                        onLoad={(map) => { gMapsRef.current = map }}
                        onZoomChanged={handleCenterChange}
                        onDragEnd={handleCenterChange}
                        center={{ lng: center.lng, lat: center.lat }}
                        mapContainerClassName="GoogleMap"
                        onClick={handleMapClick}
                        options={{
                            mapId: mapId,
                            disableDefaultUI: true,
                            zoomControl: true,
                        }}
                    >
                        <MarkerF position={{ lng: trainingSpotFormData.posX, lat: trainingSpotFormData.posY }} />
                    </GoogleMap>
                }
            </form >
        </>
    )
}