import axios from "axios";
import { defaultLocationName, googleMapsKey, ipApiUrl, locationError } from "../../constants";
import { GpsLocation } from "../Interfaces";

export const getCityName = async (lat: number, lon: number): Promise<string> => {
    try {
        const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=${googleMapsKey}`);
        const results = response.data.results;
        const city = results.find((result: { types: string | string[]; }) => result.types.includes('locality'));
        return city ? city.formatted_address : defaultLocationName;
    } catch (error) {
        console.error(error);
        return defaultLocationName;
    }
};

export const fetchIPBasedLocation = async (): Promise<GpsLocation> => {
    try {
        // throw new Error('test exception');
        const response = await axios.get(ipApiUrl);
        return {
            lng: response.data.longitude,
            lat: response.data.latitude,
            city: `${response.data.city}, ${response.data.country_name}`,
            error: undefined
        };
    } catch (error) {
        console.error(error);
        return { lat: undefined, lng: undefined, city: defaultLocationName, error: locationError };
    }
};

export const fetchGpsLocation = (): Promise<GpsLocation> => {
    return new Promise((resolve, reject) => {
        const successCallback = async (position: { coords: { latitude: any; longitude: any; }; }) => {
            const cityName = await getCityName(position.coords.latitude, position.coords.longitude);
            const locationData = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
                city: cityName,
                error: undefined
            };
            resolve(locationData);
        };

        const errorCallback = (error: any) => {
            console.error(error);
            reject({ lat: undefined, lng: undefined, city: defaultLocationName, error: locationError });
        };

        navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    });
};

export const requestAccurateGpsDetais = (setIsLoading: (arg0: boolean) => void, setGpsLocation: any) => {
    setIsLoading(true)
    fetchGpsLocation()
        .then((response) => {
            setGpsLocation(response)
        })
        .catch((error: any) => {
            setGpsLocation((prev: GpsLocation) => {
                return { ...prev, error: error.error }
            })
        })
        .finally(() => {
            setIsLoading(false)
        })
}

export const getInitialLocation = (setGpsLocation: (arg0: GpsLocation) => void) => {
    return new Promise((resolve, reject) => {
        navigator.permissions.query({ name: 'geolocation' }).then(function (result) {
            if (result.state === 'granted') {
                fetchGpsLocation()
                    .then((response) => {
                        setGpsLocation(response);
                        resolve(response);
                    })
                    .catch((error: any) => {
                        console.error('Unable to get gps data. Getting Ip based instead', error);
                        fetchIPBasedLocation()
                            .then((response: GpsLocation) => {
                                setGpsLocation(response);
                                resolve(response);
                            })
                            .catch(reject);
                    });
            } else {
                fetchIPBasedLocation()
                    .then((response: GpsLocation) => {
                        setGpsLocation(response);
                        resolve(response);
                    })
                    .catch(reject);
            }
        });
    });
};