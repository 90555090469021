import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { apiURL } from '../../constants';
import { useLoading } from '../Hooks/LoadingProvider';
import useErrorData from '../Hooks/ErrorData';
import { ErrorList } from '../Components/ErrorList/ErrorList';

export const ForgotPasswordPage = () => {
    const [email, setEmail] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const { setIsLoading } = useLoading();
    const [resetEmailSuccess, setResetEmailSuccess] = useState<boolean>(false)
    const [errorData, setErrors] = useErrorData();

    const handleChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
        setEmail(e.target.value);
    };

    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        try {
            setIsLoading(true)
            setMessage('');
            const response = await axios.post(
                `${apiURL}/api/Account/forgot-password`,
                { email }
            );
            if (response.status === 200) {
                setResetEmailSuccess(true)
                setMessage(response.data);
            }
        } catch (error: any) {
            setErrors(error.response.data.errors)
            console.log(error)
        }
        finally {
            setIsLoading(false)
        }
    };

    useEffect(() => {
        setIsLoading(false)
        // eslint-disable-next-line
    }, []);

    return (
        <div>
            <h2>Forgot Password</h2>
            {!resetEmailSuccess && <form onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="email">Email:</label>
                    <input
                        id="email"
                        name="email"
                        value={email}
                        onChange={handleChange}
                    />
                </div>
                <button type="submit">Submit</button>
            </form>}
            {message && <p>{message}</p>}
            <ErrorList errors={errorData} />
        </div>
    );
};
