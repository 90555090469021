import { ChangeEvent, useEffect, useState } from "react";
import { useLoading } from "../Hooks/LoadingProvider";
import axios from "axios";
import { apiURL } from "../../constants";
import useErrorData from "../Hooks/ErrorData";
import { ErrorList } from "../Components/ErrorList/ErrorList";
import { useAuth } from "../Hooks/AuthProvider";
import defaultImage from '../../../src/Shared/Images/default-profile-pic.jpg';

export const MyProfile = () => {
    const { setIsLoading } = useLoading();
    const [userDisplayName, setUserDisplayName] = useState<string>();
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [deletePicture, setDeletePicture] = useState<boolean>(false);
    const { user, token } = useAuth();
    const [errorData, setErrors] = useErrorData();
    const [profilePictureUrl, setProfilePicUrl] = useState<string | null>();
    const [profilePicFile, setProfilePicFile] = useState<File | null>();

    const handleProfilePicChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const file = event.target.files[0]; // Get the first file selected
            const url = URL.createObjectURL(file); // Create a URL from the file object
            setProfilePicUrl(url); // Set the profile picture URL
            setProfilePicFile(file); // Set the profile picture file
            setDeletePicture(false)
        }
    };

    const fetchUserDisplayName = async (userId: string) => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${apiURL}/api/Account/user-data/${userId}`);
            setUserDisplayName(response.data.name);
            if (response.data.profilePictureFileName?.startsWith('https')) {
                setProfilePicUrl(response.data.profilePictureFileName)
            } else if (response.data.profilePictureFileName) {
                setProfilePicUrl(`${apiURL}/api/Images/${response.data.profilePictureFileName}`)
            } else {
                setProfilePicUrl(null)
            }
        } catch (error: any) {
            console.error(error);
            setUserDisplayName('user');
            setErrors(error.data);
        } finally {
            setIsLoading(false);
        }
    };

    const updateDisplayName = async () => {
        setIsLoading(true);
        let formData = new FormData();
        formData.append('Name', userDisplayName!); // Append the new display name to formData
        deletePicture && formData.append('DeleteProfilePicture', 'True');
        profilePicFile && formData.append('ProfilePictureFile', profilePicFile); // Append the new display name to formData

        try {
            const response = await axios.put(
                `${apiURL}/api/Account/update-name`,
                formData,
                { headers: { Authorization: token } }
            );
            setUserDisplayName(response.data.name);
            setIsEditing(false);
            setErrors([])
        } catch (error: any) {
            console.error(error);
            setErrors(error.response.data.errors);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        user && fetchUserDisplayName(user.sub);
        // eslint-disable-next-line
    }, [user]);

    return (
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '0.5rem' }}>
            <h3>My profile page</h3>
            <img
                style={{ width: "20rem", height: '20rem', backgroundColor: "black", borderRadius: '50%', objectFit: 'cover' }}
                src={profilePictureUrl || defaultImage}
                alt={"profile"}
            />
            <div style={{ display: 'flex', gap: '10px' }}>
                {(profilePicFile || profilePictureUrl) && <button onClick={() => {
                    setDeletePicture(true)
                    setProfilePicUrl(null);
                    setProfilePicFile(null);
                }}>Delete picture</button>
                }
                <input
                    type="file"
                    accept="image/png, image/gif, image/jpeg"
                    onChange={handleProfilePicChange}
                    id="profile-pic-input"
                />
            </div>
            {
                isEditing ? (
                    <>
                        <input value={userDisplayName} onChange={e => setUserDisplayName(e.target.value)} />
                    </>
                ) : (
                    <div style={{ display: 'flex' }}>
                        {userDisplayName && <p>{userDisplayName}</p>}
                        <button onClick={() => setIsEditing(true)}>Edit</button>
                    </div>
                )
            }
            <button onClick={updateDisplayName}>Update Profile</button>
            <ErrorList errors={errorData} />
        </div>
    );
};
