import { useAuth } from "../Hooks/AuthProvider";
import { GpsLocation, IMapMarkers, ITrainingSpot, User } from "../Interfaces";
import { apiURL, defaultMapZoom, locationError, mapId, minZoom, replacementString, skipTakeDefault } from "../../constants";
import { TrainingSpot } from "../Components/TrainingSpot/TrainingSpot";
import { ErrorList } from "../Components/ErrorList/ErrorList";
import { useCallback, useEffect, useMemo, useState } from "react";
import axios from "axios";
import useErrorData from "../Hooks/ErrorData";
import { useLoading } from "../Hooks/LoadingProvider";
import { fetchGpsLocation, fetchIPBasedLocation } from "../Helpers/GetLocation";
import { InfoWindow, Map, MapEvent, useMap } from '@vis.gl/react-google-maps';
import geohash from 'ngeohash';
import { LoadingSpinner } from "seb-components-library";
import { mapContainerStyle } from "../Data/InitialData";
import { ProfilePicture } from "../Components/ProfilePicture/ProfilePicture";
import { AutocompleteCustomHybrid } from "../Components/Autocomplete";
import { CustomMarker } from "../Components/CustomMarkers/CustomMarker";
import { Marker, MarkerClusterer } from "@googlemaps/markerclusterer";
import { useContent } from "../Hooks/ContentProvider";

export const HomePage = () => {
    // TODO: Refactor state management, too many state values
    const { user, logout, isLoggedIn } = useAuth();
    const [gpsLocation, setGpsLocation] = useState<GpsLocation>()
    const [trainingSpotsLocationData, setTrainingSpotsLocationData] = useState<IMapMarkers[]>([]);

    // TODO: Will need to be refactored to store it in a state management system
    const [trainingSpotsByRating, setTrainingSpotsByRating] = useState<ITrainingSpot[]>([]);
    const [isLoadingMoreByRating, setIsLoadingMoreByRating] = useState<boolean>(false)
    const [byRatingSkip, setByRatingSkip] = useState<number>(0)
    const [trainingSpotsByDistance, setTrainingSpotsByDistance] = useState<ITrainingSpot[]>([]);
    const [isLoadingMoreByDistance, setIsLoadingMoreByDistance] = useState<boolean>(false)
    const [byDistanceSkip, setByDistanceSkip] = useState<number>(0)
    const [trainingSpotsByFeatured, setTrainingSpotsByFeatured] = useState<ITrainingSpot[]>([]);
    const [isLoadingMoreByFeatured, setIsLoadingMoreByFeatured] = useState<boolean>(false)
    const [byFeaturedSkip, setByFeadturedSkip] = useState<number>(0)
    const [userData, setUserData] = useState<User>()
    const [userDisplayNameLoading, setUserDisplayNameLoading] = useState<boolean>(false)
    // Until here
    const { homePage } = useContent();
    const [mapLoading, setMapLoading] = useState<boolean>(false)
    const [currentGeoHash, setCurrentGeoHash] = useState<string>()
    const [errorData, setErrors] = useErrorData();
    const { setIsLoading } = useLoading();
    const [markers, setMarkers] = useState<{ [key: string]: Marker }>({});
    const [selectedMarkerKey, setSelectedMarkerKey] = useState<string | null>(null);
    const [tempMarkerKey, setTempMarkerKey] = useState<string | null>(null);
    const map = useMap();

    const selectedMarker = useMemo(
        () =>
            trainingSpotsLocationData && selectedMarkerKey
                ? trainingSpotsLocationData.find(t => t.id === selectedMarkerKey)!
                : null,
        [trainingSpotsLocationData, selectedMarkerKey]
    );

    function forceInfoWindowClose() {
        setTempMarkerKey(null);
        setSelectedMarkerKey(null)
    }

    function resetData() {
        setTrainingSpotsByDistance([])
        setTrainingSpotsByRating([])
        setTrainingSpotsByFeatured([])
        setByDistanceSkip(0)
        setByRatingSkip(0)
        setByFeadturedSkip(0)
    }

    const clusterer = useMemo(() => {
        if (!map) return null;

        return new MarkerClusterer({ map });
    }, [map]);

    useEffect(() => {
        if (!clusterer) return;

        clusterer.clearMarkers();
        clusterer.addMarkers(Object.values(markers));
    }, [clusterer, markers]);

    // this callback will effectively get passsed as ref to the markers to keep
    // tracks of markers currently on the map
    const setMarkerRef = useCallback((marker: Marker | null, key: string) => {
        setMarkers(markers => {
            if ((marker && markers[key]) || (!marker && !markers[key]))
                return markers;

            if (marker) {
                return { ...markers, [key]: marker };
            } else {
                const { [key]: _, ...newMarkers } = markers;

                return newMarkers;
            }
        });
    }, []);

    const handleInfoWindowClose = useCallback(() => {
        setSelectedMarkerKey(null);
    }, []);

    const handleMarkerClick = useCallback((marker: IMapMarkers) => {
        forceInfoWindowClose()
        setSelectedMarkerKey(marker.id);
    }, []);

    async function fetchGlobalData(lng: number, lat: number) {
        setMapLoading(true)
        try {
            const response = await axios.get(`${apiURL}/api/TrainingSpots/location-data?posX=${lng}&posY=${lat}`);
            setTrainingSpotsLocationData(response.data);
            setTimeout(
                () => {
                    setMapLoading(false)
                }, 0
            )
        } catch (error: any) {
            console.error(error);
        }
    }

    const fetchTrainingSpotsByDistance = async (response: GpsLocation, skip: number) => {
        try {
            setErrors([])
            const trainingSpotsByDistance = await axios.get(`${apiURL}/api/TrainingSpots?posX=${response.lng}&posY=${response.lat}&skip=${skip}&take=${skipTakeDefault}`);
            setTrainingSpotsByDistance(((prev) => {
                const existingIds = new Set(prev.map((spot) => spot.id));
                const filteredNewSpots: ITrainingSpot[] = trainingSpotsByDistance.data.filter((spot: ITrainingSpot) => !existingIds.has(spot.id));
                return [...prev, ...filteredNewSpots];
            }));
        } catch (error: any) {
            console.error(error);
            setErrors(error);
        } finally {
            setIsLoadingMoreByDistance(false)
        }
    }

    const fetchTrainingSpotsByRating = async (response: GpsLocation, skip: number) => {
        try {
            setErrors([])
            const trainingSpotsByRating = await axios.get(`${apiURL}/api/TrainingSpots/TrainingSpotsByRating?posX=${response.lng}&posY=${response.lat}&skip=${skip}&take=${skipTakeDefault}`);
            setTrainingSpotsByRating((prev) => {
                const existingIds = new Set(prev.map((spot) => spot.id));
                const filteredNewSpots: ITrainingSpot[] = trainingSpotsByRating.data.filter((spot: ITrainingSpot) => !existingIds.has(spot.id));
                return [...prev, ...filteredNewSpots];
            });
        } catch (error: any) {
            console.error(error);
            setErrors(error);
        } finally {
            setIsLoadingMoreByRating(false)
        }
    }
    const fetchTrainingSpotsByFeatured = async (response: GpsLocation, skip: number) => {
        try {
            setErrors([])
            const trainingSpotsByFeatured = await axios.get(`${apiURL}/api/TrainingSpots/TrainingSpotsByFeatured?posX=${response.lng}&posY=${response.lat}&skip=${skip}&take=${skipTakeDefault}`);
            setTrainingSpotsByFeatured((prev) => {
                const existingIds = new Set(prev.map((spot) => spot.id));
                const filteredNewSpots: ITrainingSpot[] = trainingSpotsByFeatured.data.filter((spot: ITrainingSpot) => !existingIds.has(spot.id));
                return [...prev, ...filteredNewSpots];
            });
        } catch (error: any) {
            console.error(error);
            setErrors(error);
        } finally {
            setIsLoadingMoreByFeatured(false)
        }
    }

    const fetchUserDisplayName = async (userId: string) => {
        setUserDisplayNameLoading(true)
        try {
            const response = await axios.get(`${apiURL}/api/Account/user-data/${userId}`)
            setUserData(response.data)
        } catch (error: any) {
            console.error(error);
            setUserData({ name: 'user', profilePictureFileName: null })
            setErrors(error.data);
        } finally {
            setUserDisplayNameLoading(false)
        }
    }

    const fetchTrainingSpots = async (response: GpsLocation, byDistanceSkip: number, byRatingSkip: number, byFeaturedSkip: number) => {
        try {
            // not setting because map is null at this point...
            response.lng && response.lat && map?.setCenter({ lng: response.lng, lat: response.lat })
            await Promise.all([
                fetchTrainingSpotsByDistance(response, byDistanceSkip),
                fetchTrainingSpotsByRating(response, byRatingSkip),
                fetchTrainingSpotsByFeatured(response, byFeaturedSkip)
            ]);
        } catch (error: any) {
            console.error(error);
            setErrors(error);
        } finally {
            setIsLoading(false)
        }
    }

    const fetchTrainingSpotsByLocation = () => {
        navigator.permissions.query({ name: 'geolocation' }).then(async function (result) {
            if (result.state === 'granted') {
                try {
                    await fetchGpsLocation()
                        .then((res) => {
                            setGpsLocation(res)
                            res.lng && res.lat && map?.setCenter({ lng: res.lng, lat: res.lat })
                            return fetchTrainingSpots(res, byDistanceSkip, byRatingSkip, byFeaturedSkip);
                        })
                } catch (error: any) {
                    await fetchIPBasedLocation()
                        .then((res) => {
                            setGpsLocation(res)
                            res.lng && res.lat && map?.setCenter({ lng: res.lng, lat: res.lat })
                            return fetchTrainingSpots(res, byDistanceSkip, byRatingSkip, byFeaturedSkip)
                        })
                }
            } else {
                await fetchIPBasedLocation()
                    .then((res) => {
                        setGpsLocation(res)
                        res.lng && res.lat && map?.setCenter({ lng: res.lng, lat: res.lat })
                        return fetchTrainingSpots(res, byDistanceSkip, byRatingSkip, byFeaturedSkip)
                    }).catch(
                        async () => {
                            await fetchGpsLocation()
                                .then((res) => {
                                    setGpsLocation(res)
                                    res.lng && res.lat && map?.setCenter({ lng: res.lng, lat: res.lat })
                                    return fetchTrainingSpots(res, byDistanceSkip, byRatingSkip, byFeaturedSkip);
                                })
                        }
                    )
            }
        })
    };

    const handleGetLocalTrainingSpotsClick = async () => {
        setSelectedMarkerKey(null)
        setIsLoading(true)
        resetData();
        await fetchGpsLocation()
            .then(
                (res) => {
                    setGpsLocation(res)
                    res.lng && res.lat && map?.setCenter({ lng: res.lng, lat: res.lat })
                    return fetchTrainingSpots(res, byDistanceSkip, byRatingSkip, byFeaturedSkip);
                })
            .catch(async () => {
                await fetchIPBasedLocation()
                    .then((res) => {
                        setGpsLocation(res)
                        res.lng && res.lat && map?.setCenter({ lng: res.lng, lat: res.lat })
                        return fetchTrainingSpots(res, byDistanceSkip, byRatingSkip, byFeaturedSkip);
                    })
                setErrors([locationError])
            })
            .finally(() => {
                setIsLoading(false)
                map?.setZoom(defaultMapZoom)
            })
    }

    useEffect(() => {
        setIsLoading(true)
        fetchTrainingSpotsByLocation();
        // eslint-disable-next-line
    }, []);

    useEffect(
        () => {
            isLoggedIn && fetchUserDisplayName(user.sub)
            // eslint-disable-next-line
        }, [isLoggedIn]
    )

    useEffect(
        () => {
            if (!tempMarkerKey || !trainingSpotsLocationData)
                return

            if (trainingSpotsLocationData.find(x => x.id === tempMarkerKey)) {
                setSelectedMarkerKey(tempMarkerKey)
            }
        }, [trainingSpotsLocationData, tempMarkerKey]
    )

    async function handleShowOnMap(trainingSpotId: string) {
        forceInfoWindowClose();
        map?.setZoom(defaultMapZoom * 1.2)
        map?.getDiv()?.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
        if (trainingSpotsLocationData.find(x => x.id === trainingSpotId)) {
            setSelectedMarkerKey(trainingSpotId);
        } else {
            try {
                const response = await axios.get(`${apiURL}/api/TrainingSpots/${trainingSpotId}`);
                const trainingSpot: ITrainingSpot = response.data;
                map?.setCenter({ lng: trainingSpot.posX, lat: trainingSpot.posY })
                setTempMarkerKey(trainingSpotId);
            } catch (error: any) {
                setErrors(error?.response?.data?.errors)
            }
        }
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '5px' }}>
            {isLoggedIn && userData ? (
                userDisplayNameLoading ? (
                    null
                ) : (
                    <div style={{ display: 'flex', gap: '0.25rem', alignItems: 'center' }}>
                        <p>{homePage.headerLoggedIn.replace(replacementString, userData.name)}</p>
                        <ProfilePicture pictureId={userData?.profilePictureFileName} />
                    </div>
                )
            ) : (
                <p>{homePage.header}</p>
            )}


            <AutocompleteCustomHybrid
                onFocus={forceInfoWindowClose}
                onPlaceSelect={(e) => {
                    setIsLoading(true)
                    resetData()
                    map?.setCenter({ lat: e?.geometry?.location?.lat()!, lng: e?.geometry?.location?.lng()! })
                    map?.setZoom(defaultMapZoom)
                    fetchTrainingSpots({ lat: e?.geometry?.location?.lat(), lng: e?.geometry?.location?.lng(), city: e?.formatted_address! }, 0, 0, 0);
                    setGpsLocation({ lat: e?.geometry?.location?.lat(), lng: e?.geometry?.location?.lng(), city: e?.formatted_address! })
                }}
            />

            <ErrorList errors={errorData} />
            {isLoggedIn && <button onClick={() => { logout() }}>Log out</button>}
            {gpsLocation?.error && <p>{gpsLocation.error}</p>}
            {gpsLocation?.lat && gpsLocation?.lng &&
                <Map
                    style={mapContainerStyle}
                    mapId={mapId}
                    defaultCenter={{ lat: gpsLocation.lat, lng: gpsLocation.lng }}
                    zoomControl={true}
                    defaultZoom={defaultMapZoom}
                    minZoom={minZoom}
                    gestureHandling={'greedy'}
                    disableDefaultUI={true}
                    onIdle={(e: MapEvent<unknown>) => {
                        // zoom level 8 is ok for 3 characters (with neighbors)
                        let lng = e.map.getCenter()?.lng();
                        let lat = e.map.getCenter()?.lat();
                        let tempGeoHash = lng && lat && geohash.encode(lat, lng, 3);
                        if (currentGeoHash !== tempGeoHash && lat && lng) {
                            fetchGlobalData(lng, lat);
                        }
                        tempGeoHash && setCurrentGeoHash(tempGeoHash);
                    }}
                >

                    {trainingSpotsLocationData && trainingSpotsLocationData.map(coord => (
                        <CustomMarker
                            key={coord.id}
                            coord={coord}
                            onClick={handleMarkerClick}
                            setMarkerRef={setMarkerRef}
                        />
                    ))}


                    {selectedMarker && selectedMarker?.posX && selectedMarker?.posY && (
                        <InfoWindow
                            pixelOffset={[0, -30]}
                            position={{ lng: selectedMarker?.posX, lat: selectedMarker.posY }}
                            onCloseClick={handleInfoWindowClose}>
                            {selectedMarker?.id}
                        </InfoWindow>
                    )}

                    {mapLoading &&
                        <div style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            backgroundColor: 'rgba(255, 255, 255, 0.6)',
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                            <div style={{ margin: 'auto' }}>
                                <LoadingSpinner />
                            </div>
                        </div>
                    }
                </Map>
            }
            <button onClick={handleGetLocalTrainingSpotsClick}>Get accurate location</button>
            <h2>Training spots in {gpsLocation?.city}</h2>
            {trainingSpotsByDistance.length > 0 ? <h3>Nearest</h3> : <h3>No training spots found nearby</h3>}
            {
                trainingSpotsByDistance.map(trainingSpot => {
                    return (
                        <TrainingSpot key={trainingSpot.id} trainingSpot={trainingSpot} handleShowOnMap={handleShowOnMap} />
                    )
                })
            }
            {
                !isLoadingMoreByDistance ?
                    trainingSpotsByDistance.length > 0 && <button onClick={() => {
                        setIsLoadingMoreByDistance(true)
                        gpsLocation && fetchTrainingSpotsByDistance(gpsLocation, byDistanceSkip + skipTakeDefault)
                        setByDistanceSkip((prev => prev + skipTakeDefault))
                    }}>Load more</button> : <LoadingSpinner />
            }
            {trainingSpotsByRating.length > 0 && <h3>Top rated</h3>}
            {
                trainingSpotsByRating.map(trainingSpot => {
                    return (
                        <TrainingSpot key={trainingSpot.id} trainingSpot={trainingSpot} handleShowOnMap={handleShowOnMap} />
                    )
                })
            }
            {
                !isLoadingMoreByRating ?
                    trainingSpotsByRating.length > 0 && <button onClick={() => {
                        setIsLoadingMoreByRating(true)
                        gpsLocation && fetchTrainingSpotsByRating(gpsLocation, byRatingSkip + skipTakeDefault)
                        setByRatingSkip((prev => prev + skipTakeDefault))
                    }}>Load more</button> : <LoadingSpinner />
            }
            {trainingSpotsByFeatured.length > 0 && <h3>Featured</h3>}
            {
                trainingSpotsByFeatured.map(trainingSpot => {
                    return (
                        <TrainingSpot key={trainingSpot.id} trainingSpot={trainingSpot} handleShowOnMap={handleShowOnMap} />
                    )
                })
            }
            {
                !isLoadingMoreByFeatured ?
                    trainingSpotsByFeatured.length > 0 && <button onClick={() => {
                        setIsLoadingMoreByFeatured(true)
                        gpsLocation && fetchTrainingSpotsByFeatured(gpsLocation, byFeaturedSkip + skipTakeDefault)
                        setByFeadturedSkip((prev => prev + skipTakeDefault))
                    }}>Load more</button> : <LoadingSpinner />
            }
        </div >
    )
}
