export const TrainingSpotTagDictionary: { [key: string]: string } = {
    "ECCC1768-07BB-454B-866B-0D6B87197BED": "Horizontal bars",
    "F27364ED-108C-473E-BB40-607C2507B2B0": "Climbing Rope",
    "96276A57-ECB1-46D2-8F23-659A657C56AC": "Running Track",
    "1892B1ED-8549-4945-AA04-7390C14CE6B9": "Pullup Bar",
    "9A01EB71-CC83-486D-BDC9-D6B08C0E9881": "Olympic Rings"
};
export const TrainingSpotTypesDictionary: { [key: string]: string } = {
    "17C0342F-98E0-4330-88CF-6BFC63154A6C": "Indoor",
    "1B8909C4-EA07-4279-B580-C5AADB4211EE": "Outdoor"
};